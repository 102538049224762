import React from 'react';
import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Login from './screens/Login'
import Statistics from './screens/Statistics'
import Faq from './screens/Faq'
import Seminars from './screens/Seminars'
import PaymentCategories from './screens/PaymentCategories'
import News from './screens/News'
import Invoices from './screens/Invoices'
import NewsCategories from './screens/NewsCategories'
import Projects from './screens/Projects'

import FlippingBooks from './screens/FlippingBooks';
import ELibrary from './screens/ELibrary';
import Products from './screens/Products';
import ProductCategories from './screens/ProductCategories';
import Magazines from './screens/Magazines';
import MagazineSubscriptions from './screens/MagazineSubscriptions';
import UserSubscriptions from './screens/UserSubscriptions';
import Pages from './screens/Pages';
import Admins from './screens/Admins';
import Users from './screens/Users';
// import Banners from './screens/Banners';
import Educations from './screens/Educations';
import EditEducations from './screens/EditEducations';
import EditPackageSubscriptions from './screens/EditPackageSubscriptions';
import RegisteredUsers from './screens/RegisteredUsers';

import { ProvideAuth } from './contexts/Auth';
import PrivateRoute from './contexts/PrivateRoute';
import EditFlippingBooks from './screens/EditFlippingBooks';
import EditNews from './screens/EditNews';
import EditProjects from './screens/EditProjects';
import EditELibrary from './screens/EditELibrary';
import BazaZnanja from './screens/BazaZnanja';
import Slider from './screens/Slider';
import EditSlider from './screens/EditSlider';
import Materials from './screens/Materials';
import EditMaterials from './screens/EditMaterials';
import AppQuestions from './screens/AppQuestions';
import AppTop10questions from './screens/AppTop10question';
import AppForumquestion from './screens/AppForumquestion';

import ShopProducts from './screens/ShopProducts';
import EditShopProducts from './screens/EditShopProducts';
import ShopOrders from './screens/ShopOrders';
import PackageSubscriptions from './screens/PackageSubscriptions';
import Pretraga from './screens/Pretraga';
import EditEducationsMeta from './screens/EditEducationsMeta';
import Pretplate2024False from './screens/Pretplate2024False';


function App() {
  return (
    <ProvideAuth>
      <Router>
        <Switch>
          <Route exact path={"/login"}>
            <Login />
          </Route>
          <PrivateRoute exact path={["/statistics", "/"]} component={Statistics} />
          <PrivateRoute exact path={["/pretplata2024false", "/"]} component={Pretplate2024False} />
          <PrivateRoute exact path={"/slider"} component={Slider} />
          <PrivateRoute exact path={"/pretraga"} component={Pretraga} />
          <PrivateRoute exact path={"/edit-slider"} component={EditSlider} />
          <PrivateRoute exact path={"/educations"} component={Educations} />
          <PrivateRoute exact path={"/app-questions"} component={AppQuestions} />
          <PrivateRoute exact path={"/app-live-questions"} component={AppTop10questions} />
          <PrivateRoute exact path={"/forum-questions"} component={AppForumquestion} />
          <PrivateRoute exact path={"/edit-educations"} component={EditEducations} />
          <PrivateRoute exact path={"/edit-educationsmeta"} component={EditEducationsMeta} />
          <PrivateRoute exact path={"/edit-package-subscriptions"} component={EditPackageSubscriptions} />
          <PrivateRoute exact path={"/materials"} component={Materials} />
          <PrivateRoute exact path={"/edit-materials"} component={EditMaterials} />
          <PrivateRoute exact path={"/faq"} component={Faq} />
          <PrivateRoute exact path={"/seminars"} component={Seminars} />
          <PrivateRoute exact path={"/registered-users"} component={RegisteredUsers} />
          <PrivateRoute exact path={"/payment-categories"} component={PaymentCategories} />
          <PrivateRoute exact path={"/news"} component={News} />
          <PrivateRoute exact path={"/invoices"} component={Invoices} />
          <PrivateRoute exact path={"/edit-news"} component={EditNews} />
          <PrivateRoute exact path={"/news-categories"} component={NewsCategories} />
          <PrivateRoute exact path={"/projects"} component={Projects} />
          <PrivateRoute exact path={"/baza-znanja"} component={BazaZnanja} />
          <PrivateRoute exact path={"/edit-projects"} component={EditProjects} />
          <PrivateRoute exact path={"/flipping-books"} component={FlippingBooks} />
          <PrivateRoute exact path={"/edit-flipping-books"} component={EditFlippingBooks} />
          <PrivateRoute exact path={"/e-library"} component={ELibrary} />
          <PrivateRoute exact path={"/edit-elibrary"} component={EditELibrary} />
          <PrivateRoute exact path={"/shop-products"} component={ShopProducts} />
          <PrivateRoute exact path={"/edit-shop-products"} component={EditShopProducts} />
          <PrivateRoute exact path={"/shop-orders"} component={ShopOrders} />
          <PrivateRoute exact path={"/products"} component={Products} />
          <PrivateRoute exact path={"/product-categories"} component={ProductCategories} />
          <PrivateRoute exact path={"/magazines"} component={Magazines} />
          <PrivateRoute exact path={"/magazine-subscriptions"} component={MagazineSubscriptions} />
          <PrivateRoute exact path={"/user-subscriptions"} component={UserSubscriptions} />
          <PrivateRoute exact path={"/pages"} component={Pages} />
          <PrivateRoute exact path={"/pretplate"} component={PackageSubscriptions} />
          {/* <PrivateRoute exact path={"/gallery"} component={Gallery} /> */}
          <PrivateRoute exact path={"/admins"} component={Admins} />
          
          <PrivateRoute exact path={"/users"} component={Users} />
          {/* <PrivateRoute exact path={"/banners"} component={Banners} /> */}
          <PrivateRoute exact path={"/settings"}>
            <p>settings</p>
          </PrivateRoute>
          <PrivateRoute exact path={"/mailchimp"}>
            <p>mailchimp</p>
          </PrivateRoute>
          <PrivateRoute exact path={"/logout"}>
            <p>logout</p>
          </PrivateRoute>
          <PrivateRoute path={'*'} >
            <p>404</p>
          </PrivateRoute>
        </Switch>
      </Router>
    </ProvideAuth>
  );
}

export default App;
