import React, { useState, useEffect } from 'react';
import { Container, QuestionsContainer, PageTitle, Table } from '../components/tables/Style';
import { AddButton, UpdateButton, DeleteButton } from '../components/tables/Buttons';
import { getAllCollection } from '../services/database';

const Slider = ({ history }) => {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        getAllCollection('slider_items').then(result => {
            if (result.data) {
                // Sortiranje prema order_number (ako postoji, inače 999 kao fallback)
                const sortedData = result.data.sort((a, b) => (a.order_number || 999) - (b.order_number || 999));
                setRows(sortedData);
                console.log('Sortirani podaci:', sortedData);
            }
        });
    }, []);

    const handleEdit = (orderNumber) => {
        if (!orderNumber) {
            console.error('order_number je undefined! Provjeri bazu i mapiranje podataka.');
            return;
        }
        console.log('Prosljeđujem order_number:', orderNumber);
        history.push('/edit-slider', { order_number: orderNumber.toString() }); // Prosljeđujemo order_number umjesto ID-a
    };

    const handleDelete = (orderNumber) => {
        console.log('Brišem slider sa order_number:', orderNumber);
        // Implementacija brisanja...
    };

    return (
        <Container>
            <PageTitle>Slider</PageTitle>
            <QuestionsContainer>
                <Table>
                    <thead>
                        <tr>
                            <th>Naslov</th>
                            <th>Order Number</th>
                            <th>Aktivan</th>
                            <th>Akcije</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((slide) => (
                            <tr key={slide.order_number}>
                                <td>{slide.title || 'Bez naslova'}</td>
                                <td>{slide.order_number || 'N/A'}</td>
                                <td>{slide.active ? '✅' : '❌'}</td>
                                <td>
                                    <UpdateButton onClick={() => handleEdit(slide.order_number)}>
                                        <i className="fas fa-edit"></i> Edit
                                    </UpdateButton>
                                    <DeleteButton onClick={() => handleDelete(slide.order_number)}>
                                        <i className="fas fa-trash-alt"></i> Delete
                                    </DeleteButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </QuestionsContainer>
        </Container>
    );
};

export default Slider;
