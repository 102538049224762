import React, { useEffect, useState } from 'react';

//Styled and native components
import { Container, Loader } from '../components/tables/Style'
import { AddButton } from '../components/tables/Buttons'

//React components
import { InputArea } from './Styles/Inputs';
import JSZip from 'jszip';

//Modules
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import '../style/editorStyle.css';

//Hooks
import { withRouter } from 'react-router-dom';

//Services
import { addToCollection, queryCollection } from '../services/database'
import { uploadFile } from '../services/storage';

const EditNews = ({ history }) => {
    //State
    const [loading, setLoading] = useState(false);
    const [inputs, setInputs] = useState({
        id: '',
        title: '',
        image: '',
        date: '',
        short_text: '',
        date2: '',
    });
    const [editorData, setEditorData] = useState('');

    //Effect
    useEffect(() => {
        if (!history?.location?.state?.id) return; // Provjera da li postoji ID
    
        queryCollection('news', 'id', '==', parseInt(history.location.state.id))
            .then(result => {
                console.log(result);
                if (result?.data?.length > 0) {
                    setInputs(result.data[0]);
                    setEditorData(result.data[0].long_text || '');  // Osiguraj defaultnu vrijednost
                }
            })
            .catch(error => console.error("❌ Greška prilikom dohvaćanja podataka:", error));
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
    
        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const arrayBuffer = e.target.result;
                const zip = new JSZip();
                const doc = await zip.loadAsync(arrayBuffer);
                const xml = await doc.file("word/document.xml").async("text");
    
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(xml, "text/xml");
                const body = xmlDoc.getElementsByTagName("w:body")[0];
                let htmlContent = "";
    
                for (let i = 0; i < body.childNodes.length; i++) {
                    const node = body.childNodes[i];
    
                    if (node.nodeName === "w:p") {
                        let styles = `box-sizing: border-box; margin: 2px 0px 0px 0px; padding-bottom: 2px; 
                            color: rgb(33, 37, 41); font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', 
                            'Noto Sans', 'Liberation Sans', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 
                            'Segoe UI Symbol', 'Noto Color Emoji'; font-size: 16px; font-weight: 400; text-align: start; 
                            line-height: 1.1; white-space: normal; word-spacing: 0px;`;
    
                        const runs = node.getElementsByTagName("w:r");
                        let spanGroup = "";
    
                        for (let j = 0; j < runs.length; j++) {
                            const run = runs[j];
                            let style = `box-sizing: border-box;`;
                            let isBold = run.getElementsByTagName("w:b").length > 0;
                            let isItalic = run.getElementsByTagName("w:i").length > 0;
                            let isUnderline = run.getElementsByTagName("w:u").length > 0;
                            let isStrikethrough = run.getElementsByTagName("w:strike").length > 0;
    
                            // Formatiraj stilove
                            if (isBold) style += " font-weight: bold;";
                            if (isItalic) style += " font-style: italic;";
                            if (isUnderline) style += " text-decoration: underline;";
                            if (isStrikethrough) style += " text-decoration: line-through;";
    
                            const textNodes = run.getElementsByTagName("w:t");
                            for (let k = 0; k < textNodes.length; k++) {
                                let textContent = textNodes[k].textContent.trim();
                                if (textContent) {
                                    spanGroup += `<span style="${style}">${textContent}</span> `;
                                }
                            }
                        }
    
                        // Obradi hyperlinkove
                        const hyperlinks = node.getElementsByTagName("w:hyperlink");
                        for (let h = 0; h < hyperlinks.length; h++) {
                            const hyperlink = hyperlinks[h];
                            const relationshipId = hyperlink.getAttribute("r:id");
    
                            // Pronađi URL iz rels fajla
                            const relsFile = await doc.file("word/_rels/document.xml.rels").async("text");
                            const relsDoc = parser.parseFromString(relsFile, "text/xml");
                            const relationships = relsDoc.getElementsByTagName("Relationship");
    
                            let hyperlinkUrl = "";
                            for (let r = 0; r < relationships.length; r++) {
                                if (relationships[r].getAttribute("Id") === relationshipId) {
                                    hyperlinkUrl = relationships[r].getAttribute("Target");
                                    break;
                                }
                            }
    
                            // Ako smo pronašli URL, dodaj ga u HTML
                            if (hyperlinkUrl) {
                                const hyperlinkText = hyperlink.textContent.trim();
                                spanGroup += `<a href="${hyperlinkUrl}" style="color: blue; text-decoration: underline;">${hyperlinkText}</a> `;
                            }
                        }
    
                        if (spanGroup.trim() !== "") {
                            htmlContent += `<p style="${styles}">${spanGroup.trim()}</p>`;
                        }
                    }
                }
    
                setEditorData(htmlContent);
                console.log("✅ Generisani HTML:", htmlContent);
            } catch (error) {
                console.error("❌ Greška pri konverziji .docx u HTML:", error);
            }
        };
    
        reader.readAsArrayBuffer(file);
    };
    
    
    //Functions
    const handleSubmit = () => {
        addToCollection('news', (history.location.state.id).toString(), { 
            ...inputs, 
            long_text: editorData // Sprema editovani tekst
        });
        history.push('news');
    };
    

    return (
        <Container>
            <h1 style={{ color: '#fff' }}>Naslov:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, title: e.target.value });
                }}
                value={inputs.title}
            />

            <h1 style={{ color: '#fff' }}>Datum:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, date: e.target.value });
                }}
                value={inputs.date}
            />

            <h1 style={{ color: '#fff' }}>Datum za sortiranje (yyyymmdd):</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, date2: e.target.value });
                }}
                value={inputs.date2}
            />

            <h1 style={{ color: '#fff' }}>Kratki opis:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, short_text: e.target.value });
                }}
                value={inputs.short_text}
            />

            <h1 style={{ color: '#fff' }}>Slika:</h1>
            {loading ?
                <Loader />
                :
                <input
                    type='file'
                    onChange={e => {
                        setLoading(true);
                        uploadFile(e.target.files[0]).then(result => {
                            setLoading(false);
                            if (result.status === 200) {
                                setInputs({ ...inputs, image: result.link });
                            }
                            else alert('Something went wrong with file upload');
                        })
                    }}
                />
            }

            <InputArea
                disabled
                onChange={e => {
                    setInputs({ ...inputs, image: e.target.value });
                }}
                value={inputs.image}
            />

            <div style={{ height: 50 }} />

            {/* <CKEditor
                editor={ClassicEditor}
                data={editorData ? editorData : '<p></p>'}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    setEditorData(data);
                }}
            /> */}
            <h1 style={{ color: '#fff' }}>Dugi tekst (Upload `.docx` sa stilovima + editovanje):</h1>
<input type="file" accept=".docx" onChange={handleFileUpload} />

<div 
    contentEditable="true"
    onInput={(e) => setEditorData(e.target.innerHTML)}
    dangerouslySetInnerHTML={{ __html: editorData || '' }}
    style={{ 
        border: '1px solid #ccc', 
        padding: '10px', 
        minHeight: '200px', 
        backgroundColor: '#fff', 
        outline: 'none' 
    }} 
/>



            <div style={{ height: 50 }} />

            <AddButton onClick={() => handleSubmit()}>
                <i className="fas fa-plus-circle"></i>
                <h1>Sačuvaj</h1>
            </AddButton>
        </Container>
    )
}

export default withRouter(EditNews);
