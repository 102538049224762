import React, { useEffect, useState } from 'react';
import { Container, Loader } from '../components/tables/Style';
import { AddButton } from '../components/tables/Buttons';
import { queryCollection, deleteFromCollection, addToCollection } from '../services/database';
import styled from 'styled-components';

// Stilizirani elementi
const StyledInput = styled.input`
    width: 100%;
    height: 40px;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
`;

const StyledCheckbox = styled.input`
    margin: 10px;
`;

const StyledH1 = styled.h1`
    color: #fff;
    font-size: 18px;
    margin: 15px 0 5px;
`;

const EditSlider = ({ history, location }) => {
    const [loading, setLoading] = useState(false);
    const [originalId, setOriginalId] = useState(null);
    const [originalOrderNumber, setOriginalOrderNumber] = useState(null);
    const [inputs, setInputs] = useState({
        image_url: '',
        title: '',
        link: '',
        subtitle: '',
        order_number: '',
        active: false,
    });

    useEffect(() => {
        if (!location.state || !location.state.order_number) {
            console.error('Greška: Nema order_number parametra.');
            history.push('/slider');
            return;
        }

        setLoading(true);
        console.log('Primljeni order_number za dohvaćanje dokumenta:', location.state.order_number);

        queryCollection('slider_items', 'order_number', '==', location.state.order_number)
            .then((result) => {
                if (result.data.length > 0) {
                    const slide = result.data[0]; // Dohvati slider iz baze
                    setInputs({
                        image_url: slide.image_url || '',
                        title: slide.title || '',
                        link: slide.link || '',
                        subtitle: slide.subtitle || '',
                        order_number: slide.order_number || '',
                        active: slide.active || false,
                    });
                    setOriginalId(slide.id); // Čuvamo ID dokumenta
                    setOriginalOrderNumber(slide.order_number); // Čuvamo originalni order_number
                    console.log('Dohvaćeni podaci za edit:', slide);
                } else {
                    console.error('Nema slidera sa tim order_number.');
                    history.push('/slider');
                }
            })
            .catch((error) => {
                console.error('Greška prilikom dohvaćanja:', error);
            })
            .finally(() => setLoading(false));

    }, [history, location]);

    const handleSaveButton = async () => {
        if (!originalId) {
            alert('Greška: Ne mogu pronaći originalni dokument.');
            return;
        }

        setLoading(true);

        try {
            // Ako se order_number promijenio, brišemo originalni slider po ID-u
            if (originalOrderNumber !== inputs.order_number) {
                await deleteFromCollection('slider_items', originalId);
                console.log(`Obrisan stari slider sa ID: ${originalId}`);
            }

            // Kreiramo novi slider sa novim order_number
            await addToCollection('slider_items', inputs.order_number.toString(), inputs);

            console.log('Uspješno ažuriran slider:', inputs);
            history.push('/slider'); // Vraćanje na listu
        } catch (error) {
            console.error('Greška prilikom čuvanja slidera:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <StyledH1>Redni broj:</StyledH1>
                    <StyledInput
                        type="number"
                        value={inputs.order_number}
                        onChange={(e) => setInputs({ ...inputs, order_number: e.target.value })}
                    />

                    <StyledH1>Naslov:</StyledH1>
                    <StyledInput
                        type="text"
                        value={inputs.title}
                        onChange={(e) => setInputs({ ...inputs, title: e.target.value })}
                    />

                    <StyledH1>Podnaslov:</StyledH1>
                    <StyledInput
                        type="text"
                        value={inputs.subtitle}
                        onChange={(e) => setInputs({ ...inputs, subtitle: e.target.value })}
                    />

                    <StyledH1>Link:</StyledH1>
                    <StyledInput
                        type="text"
                        value={inputs.link}
                        onChange={(e) => setInputs({ ...inputs, link: e.target.value })}
                    />

                    <StyledH1>Slika (URL):</StyledH1>
                    <StyledInput
                        type="text"
                        value={inputs.image_url}
                        onChange={(e) => setInputs({ ...inputs, image_url: e.target.value })}
                    />

                    <StyledH1>Aktivan:</StyledH1>
                    <label>
                        <StyledCheckbox
                            type="checkbox"
                            checked={inputs.active}
                            onChange={(e) => setInputs({ ...inputs, active: e.target.checked })}
                        />
                        Aktiviraj
                    </label>

                    <AddButton onClick={handleSaveButton}>Sačuvaj</AddButton>
                </>
            )}
        </Container>
    );
};

export default EditSlider;
