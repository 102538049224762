import React, { useEffect, useState } from 'react';

// Styled components
import { AddButton } from '../components/tables/Buttons';
import styled from 'styled-components';


// Services
import { queryCollection } from '../services/database';
import firebase from '../config/firebase'; // Dodaj import za direktno Firebase ažuriranje

const Pretplate2024False = ({ isSidebarOpen }) => {
    // State za podatke
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(false);

    // useEffect za dohvatanje pristupnih podataka
    const [users, setUsers] = useState([]);
    const Loader = () => <div style={{ color: '#fff', textAlign: 'center' }}>Učitavanje...</div>;
    

useEffect(() => {
    queryCollection('users', 'email', '!=', '') // Dohvati sve korisnike koji imaju email
        .then(result => {
            if (result.data) {
                setUsers(result.data);
            }
        })
        .catch(error => {
            console.error("Greška pri dohvaćanju korisnika:", error);
        });
}, []);

    // useEffect za dohvaćanje pretplata za 2024. godinu
    useEffect(() => {
        setLoading(true);
        queryCollection('package_subscriptions', 'year', '==', "2025")
            .then(result => {
                console.log("Dohvaćeni podaci iz Firestore-a:", result.data); // 🟢 Debugging log
                if (result.data && result.data.length > 0) {
                    setSubscriptions(result.data);
                } else {
                    console.warn("Firestore nije vratio nijedan dokument.");
                }
                setLoading(false);
            })
            .catch(error => {
                console.error("Greška pri dohvaćanju podataka:", error);
                setLoading(false);
            });
    }, []);
    
    useEffect(() => {
        setLoading(true);
        setTimeout(() => setLoading(false), 1000); // Simulacija učitavanja
    }, []);
    // Funkcija za ažuriranje statusa `paid` na false
    const updatePaidStatus = async () => {
        if (subscriptions.length === 0) {
            alert('Nema pretplata za ažuriranje.');
            return;
        }
    
        setLoading(true);
        try {
            const db = firebase.firestore();
            const batch = db.batch();
    
            subscriptions.forEach(sub => {
                if (sub.id) {
                    const docRef = db.collection('package_subscriptions').doc(sub.id);
                    batch.update(docRef, { paid: false });
    
                    console.log(`Ažuriram dokument: ${sub.id}, postavljam paid: false`); // 🟢 Debugging log
                } else {
                    console.warn("Pronađen dokument bez ID-a:", sub);
                }
            });
    
            await batch.commit(); // Čekamo da Firestore završi sve upite
            console.log("Batch commit završen! 🔥"); // 🟢 Debugging log
    
            setSubscriptions(prev =>
                prev.map(sub => ({
                    ...sub,
                    paid: false
                }))
            );
    
            alert('Svi paketi za 2024. su ažurirani na paid: false!');
        } catch (error) {
            console.error("Greška pri ažuriranju pretplata:", error);
            alert('Došlo je do greške pri ažuriranju.');
        }
        setLoading(false);
    };
    
    

    return (
        <Container isSidebarOpen={isSidebarOpen}>
        <h1>Pregled pretplata za 2025.</h1>

        {loading ? (
            <Loader />
        ) : (
             <div className="table-wrapper">
                    <table className="custom-table">
                        <thead>
                            <tr>
                                {/* <th>ID</th> */}
                                <th>Company</th>
                                <th>Korisnik</th>
                                <th>Plaćeno</th>
                                <th>Predracun</th>
                                <th>Email</th>
                                <th>Password</th>

                            </tr>
                        </thead>
                        <tbody>
                            {subscriptions.map(sub => (
                                <tr key={sub.id}>
                                    {/* <td>{sub.id}</td> */}
                                    <td>{sub.company}</td>
                                    <td>{sub.user || 'Nepoznato'}</td>
                                    <td className={sub.paid ? "status-paid" : "status-unpaid"}>
                                        {sub.paid ? '✅ Da' : '❌ Ne'}
                                    </td>
                                    <td>
                                        {/* 🆕 Link ka PDF-u */}
                                        <a 
                                            href={`https://revicon.info/api/get-package-pdf?token=${sub.id}`} 
                                            target="_blank" 
                                            rel="noopener noreferrer"
                                            className="pdf-link"
                                        >
                                            PDF link
                                        </a>
                                    </td>
                                    <td>{(users.find(user => user.email === sub.email) || {}).email || 'N/A'}</td>
<td>{(users.find(user => user.email === sub.email) || {}).password || 'N/A'}</td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            <div style={{ height: 20 }} />

            {/* <AddButton onClick={updatePaidStatus} disabled={loading} className="update-button">
                <i className="fas fa-sync-alt"></i>
                <h1>{loading ? "Ažuriranje..." : "Ažuriraj plaćanja"}</h1>
            </AddButton> */}

            {/* Dodani stilovi */}
            <style jsx>{`
                .table-wrapper {
                    width: 100%;
                    max-width: 1200px;
                    margin: 0 auto;
                    overflow-x: auto;
                }

                .custom-table {
                    width: 100%;
                    border-collapse: collapse;
                    background-color: #222;
                    color: #fff;
                    font-size: 16px;
                    border-radius: 10px;
                    overflow: hidden;
                }

                .custom-table th, .custom-table td {
                    padding: 12px 15px;
                    text-align: left;
                }

                .custom-table th {
                    background-color: #333;
                    color: #f1f1f1;
                    font-weight: bold;
                }

                .custom-table tbody tr:nth-child(even) {
                    background-color: #2a2a2a;
                }

                .custom-table tbody tr:hover {
                    background-color: #3a3a3a;
                }

                .status-paid {
                    color: #4caf50;
                    font-weight: bold;
                }

                .status-unpaid {
                    color: #ff4444;
                    font-weight: bold;
                }

                .update-button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 20px auto;
                    padding: 10px 20px;
                    background-color: #ff9800;
                    border-radius: 8px;
                    transition: 0.3s;
                }

                .update-button:hover {
                    background-color: #e68900;
                }
            `}</style>
        </Container>
    );
};


export default Pretplate2024False;
// Styled komponenta za glavni sadržaj
const Container = styled.div`
    transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out;
    width: ${({ isSidebarOpen }) => (isSidebarOpen ? 'calc(100% - 250px)' : '100%')};
    margin-left: ${({ isSidebarOpen }) => (isSidebarOpen ? '250px' : '0')};
    padding: 20px;
    box-sizing: border-box;
`;