import React, { useState, useEffect } from 'react';

import { Container, QuestionsContainer, PageTitle, Table } from '../components/tables/Style'
import { AddButton, UpdateButton, DeleteButton, CancleButton } from '../components/tables/Buttons'

import AddPackageSubscription from '../components/AddPackageSubscription'
import { InputArea } from './Styles/Inputs';

import { getAllCollection, addToCollection, deleteFromCollection, editDocument, getAllCollectionWithDocIdAndValuesInCondition, queryCollection, getDocumnetByDocId, getAllCollectionWithDocIdAndCriteria } from '../services/database';

import { withRouter } from 'react-router-dom';
import CsvDownload from 'react-json-to-csv'

const PackageSubscriptions = ({ history }) => {
    //State
    const [rows, setRows] = useState([]);
    const [shownRows, setShowRows] = useState([]);
    const [yearsDropdow, setYearsDropdown] = useState('2024');
    const [packageDropdown, setPackageDropdown] = useState('');
    const [searchFiled, setSearchField] = useState('');
    const [invoice, setInvoice] = useState('');
    const [selectedOption, setSelectedOption] = useState([]);
    const [showAddRow, setShowAddRow] = useState(false);
    const [newUser, setNewUser] = useState();
    const [inputs, setInputs] = useState({
        date: '',
        user: '',
        paid: false,
        package_name: '',
        description: '',
    });
    const [editPaid, setEditPaid] = useState(false);
    const [updateRowId, setUpdateRowId] = useState('');
    const [editPaid2, setEditPaid2] = useState(false);
    const [updateRowId2, setUpdateRowId2] = useState('');
    const [rowUpdated, setRowUpdated] = useState('');
    const [totalPaid, setTotalPaid] = useState('');
    const [totalRevicon, setTotalRevicon] = useState('');
    const [totalBasic, setTotalBasic] = useState('');
    const [totalBasicPaid, setTotalBasicPaid] = useState('');
    const [totalBetterValue, setTotalBetterValue] = useState('');
    const [totalBetterValuePaid, setTotalBetterValuePaid] = useState('');
    const [totalPremium, setTotalPremium] = useState('');
    const [totalPremiumPaid, setTotalPremiumPaid] = useState('');
    const [totalRC, setTotalRC] = useState('');
    const [totalRCPaid, setTotalRCPaid] = useState('');

    const basic =  ['BASIC', 'Pretplatnički paket: BASIC'];
    const better_value = ['BEST VALUE', 'Pretplatnički paket: BEST VALUE', 'Pretplatnički paket: BETTER VALUE'];
    const premium =  ['PREMIUM', 'Pretplatnički paket: PREMIUM'];
    const revicon_club = ['REVICON CLUB', 'Pretplatnički paket: REVICON CLUB'];


            
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    useEffect(() => {
        getAllCollectionWithDocIdAndValuesInCondition('package_subscriptions', yearsDropdow, packageDropdown).then(result => {
            setRows(result.data);
            setShowRows(result.data);
            const paid = result.data.filter(res => res.data.paid === true && (!res.data.email.toString().includes("@revicon.info") || !res.data.email.toString().includes("@revicon.ba")));
            setTotalPaid(paid.length)

            const revicon = result.data.filter(res => res.data.email.toString().includes("@revicon.info") || res.data.email.toString().includes("@revicon.ba"));
            setTotalRevicon(revicon.length)

            const basic_count = result.data.filter(res => basic.includes(res.data.package_name) && (!res.data.email.toString().includes("@revicon.info") || !res.data.email.toString().includes("@revicon.ba"))).length;
            const basic_count_paid = result.data.filter(res => basic.includes(res.data.package_name) && res.data.paid === true && (!res.data.email.toString().includes("@revicon.info") || !res.data.email.toString().includes("@revicon.ba"))).length;
            setTotalBasic(basic_count)
            setTotalBasicPaid(basic_count_paid)

            const better_value_count = result.data.filter(res => better_value.includes(res.data.package_name) && (!res.data.email.toString().includes("@revicon.info") || !res.data.email.toString().includes("@revicon.ba"))).length;
            const better_value_count_paid = result.data.filter(res => better_value.includes(res.data.package_name) && res.data.paid === true && (!res.data.email.toString().includes("@revicon.info") || !res.data.email.toString().includes("@revicon.ba"))).length;
            setTotalBetterValue(better_value_count)
            setTotalBetterValuePaid(better_value_count_paid)

            const premium_count = result.data.filter(res => premium.includes(res.data.package_name) && (!res.data.email.toString().includes("@revicon.info") || !res.data.email.toString().includes("@revicon.ba"))).length;
            const premium_count_paid = result.data.filter(res => premium.includes(res.data.package_name) && res.data.paid === true && (!res.data.email.toString().includes("@revicon.info") || !res.data.email.toString().includes("@revicon.ba"))).length;
            setTotalPremium(premium_count)
            setTotalPremiumPaid(premium_count_paid)

            const rc_count = result.data.filter(res => revicon_club.includes(res.data.package_name) && (!res.data.email.toString().includes("@revicon.info") || !res.data.email.toString().includes("@revicon.ba"))).length;
            const rc_paid_count = result.data.filter(res => revicon_club.includes(res.data.package_name) && res.data.paid === true && (!res.data.email.toString().includes("@revicon.info") || !res.data.email.toString().includes("@revicon.ba"))).length;
            setTotalRC(rc_count)
            setTotalRCPaid(rc_paid_count)
        })
    }, [packageDropdown, yearsDropdow]);

    //Functions
    const handleAddButton = () => {
        setShowAddRow(true);
    }

    const handleNewSubscription = () => {
        setShowModal((shwMod) => !shwMod);
    }
    const handleUpdateRow = (id) => {
        history.push('/edit-package-subscriptions', {
            id: id
        });
    }
    
    const handleSearch = (e, searchByInvoiceNumber) => {
        setSearchField(e.target.value);
        if(e.target.value === ''){
            setShowRows(rows);
            setEditPaid(false);
        }else{
            setShowRows(rows.filter(row => row.data.user?.includes(e.target.value)));
            setEditPaid(false);
        }
    }

    const searchByInvoice = (e) => {
        console.log('Searching...', e.target.value);
        setInvoice(e.target.value)

        if(e.target.value === ''){
            setShowRows(rows);
            setEditPaid(false);
        } else{
            setShowRows(rows.filter(row => row.data.invoiceId?.includes(e.target.value)));
            setEditPaid(false);
        }
    }

    const handleAddButton2 = (id) => {
            editDocument('package_subscriptions', id, 'description', inputs.description).then(result => {
                getAllCollectionWithDocIdAndValuesInCondition('package_subscriptions', yearsDropdow, packageDropdown).then(result => {
                    setRows(result.data);
                    setShowRows(result.data);
                    setEditPaid2(false);
                    setUpdateRowId2('');
                    setSearchField('');
                    setInputs({ ...inputs, description: ''});
                })
            }).catch(e => console.log(e));
    }

    function handleDescription(id, description) {
        return (<>
            { description? 
            <div style={{width : '130px'}}>
                <div>
                    <InputArea
                        onChange={e => {
                            setInputs({ ...inputs, description: e.target.value });
                        }}
                        value={inputs.description}
                    />
                    </div>
                </div> :
                <div style={{width : '130px'}}>
                    <div>
                    <InputArea
                        onChange={e => {
                            setInputs({ ...inputs, description: e.target.value });
                        }}
                        value={inputs.description}
                    />
                    </div>
                </div>
            }
        </>)
      }

    const handleSaveButton = () => {
        if (inputs.date && inputs.user) {
            setShowAddRow(false);
            addToCollection(
                'package_subscriptions',
                (shownRows.length > 0 ? /*parseInt(rows[rows.length - 1].id)*/Math.max.apply(Math, shownRows.map(function (o) { return o.id; })) + 1 : 0).toString(),
                {
                    id: shownRows.length > 0 ? /*parseInt(rows[rows.length - 1].id)*/Math.max.apply(Math, shownRows.map(function (o) { return o.id; })) + 1 : 0,
                    date: inputs.date,
                    user: inputs.user,
                    paid: inputs.paid,
                    package_name: inputs.package_name
                }
            );
            getAllCollection('educations').then(result => {
                setRows(result.data);
            })
            setInputs({
                date: '',
                user: '',
                paid: false,
                package_name: ''
            });
        }
    }

    const handleDelete = (dateId) => {
        deleteFromCollection('package_subscriptions', dateId).then(result => {
            window.location.reload();    
        });
        
    }

    const handleUpdate = (id) => {
        setUpdateRowId(id);
        setEditPaid(true);
        
        // history.push('/edit-educations', {
        //     id: id
        // });
    }
    const handleUpdate2 = (id) => {
        setUpdateRowId2(id);
        setEditPaid2(true);
        
        // history.push('/edit-educations', {
        //     id: id
        // });
    }
    const generateFileName = () => {
        const date = new Date();
        const temp = date.toString();
        let fileName = `${temp.slice(4, 7)}-${temp.slice(8, 10)}-${temp.slice(11, 15)}-${temp.slice(16, 18)}-${temp.slice(19, 21)}.csv`

        return fileName;
    }
    const handleYearsDropdown = (e) => {
        setYearsDropdown(e.target.value)
        getAllCollectionWithDocIdAndValuesInCondition('package_subscriptions', e.target.value, packageDropdown).then(result => {
            setRows(result.data);
            setShowRows(result.data);
            setSearchField('');

            const revicon = result.data.filter(res => res.data.paid === true && res.data.email.toString().includes("@revicon.info"));
            const paid = result.data.filter(res => res.data.paid === true );
            setTotalPaid(paid.length)
            setTotalRevicon(paid.length)

        })    
    }

    const handlePackageDropdown = (e) => {
        setPackageDropdown(e.target.value)
        getAllCollectionWithDocIdAndValuesInCondition('package_subscriptions', yearsDropdow, e.target.value).then(result => {
            setRows(result.data);
            setShowRows(result.data);
            setSearchField('');

            const paid = result.data.filter(res => res.data.paid === true);
            console.log('here is paid', paid.length);
            setTotalPaid(paid.length)
        })    
    }

    function EditPaid(paid, i, rowToUpdate) {
        let isPaid = paid;
        const handleChange = (e) =>{
            isPaid = !isPaid
        }
        const submitPayed = () => {
            setSearchField('');
            setEditPaid(false);
            editDocument('package_subscriptions', i, 'paid', isPaid).then(result => {
                const date = (new Date()).toString()                
                editDocument('package_subscriptions', i, 'paid_date', date).then(result => {
                    getAllCollectionWithDocIdAndValuesInCondition('package_subscriptions', yearsDropdow, packageDropdown).then(result => {
                        setRows(result.data);
                        setShowRows(result.data);
                    })     
                });
            });
            
            
        }
        return (<>
            {
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <input type='checkbox' defaultChecked={isPaid} onChange={handleChange} value={isPaid}></input>
                    <input type='submit' style={{cursor: 'pointer', borderRadius: '4px',borderColor:'#27C24C', backgroundColor:'#27C24C'}} onClick={submitPayed} value={"Potvrdi"} />
                </div>
            }
        </>)
    }

    const editNewUser = (user, event, userId) => {

        setNewUser(event.currentTarget.checked);

        if (event.currentTarget.checked) {
            user.data.isNewUser = true;
        } else {
            user.data.isNewUser = false;
        }

        
        editDocument('package_subscriptions', userId, 'isNewUser', user.data.isNewUser).then(result => {
            getAllCollectionWithDocIdAndValuesInCondition('package_subscriptions', yearsDropdow, packageDropdown).then(result => {
                setRows(result.data);
                setShowRows(result.data);
            });
        });
        
        console.log(user.data);

    }

    return (
        <Container>
            <AddPackageSubscription showModal={showModal} handleShowModal={handleNewSubscription}/>
            
            <div style={{ display: 'flex', justifyContent: 'space-between', height:"40px" }}>
                <PageTitle>Pretplate (godisnje)</PageTitle>
                <AddButton onClick={() => handleNewSubscription()}>
                    <i className="fas fa-plus-circle"></i>
                    <h1>Dodaj</h1>
                </AddButton>
            </div>
            <QuestionsContainer>
            <h2 style={{ color: '#f1f1f1' }}>Broj pretplata: {selectedOption} {selectedOption ? `(${rows.length})` : false}</h2>
                <h2 style={{color: "#f1f1f1"}}>
                        Odaberite godinu
                </h2>
                <div style={{ display: 'flex', justifyContent: 'space-between', height:"40px" }} >
                
                <select className="select-box" style={{ width: '30%'}} value={yearsDropdow} onChange={handleYearsDropdown}>
                    <option key="0" value={'2024'}>2024</option>
                    <option key="1" value={'2025'}>2025</option>
                    <option key="1" value={''}>sve</option>
                </select>
                    
                <input id='seach' placeholder='Pretraži po korisniku' value={searchFiled} onChange={(e) => handleSearch(e)} />
                <input id='seach-by-invoiceId' placeholder='Pretraži po predračunu' value={invoice} onChange={(e) => {searchByInvoice(e)}} />    
                <select className="select-box" style={{ width: '30%'}} value={packageDropdown} onChange={handlePackageDropdown}>
                    <option key="0" value={''}>Odaberite paket</option>
                    <option key="1" value={'basic'}>BASIC</option>
                    <option key="2" value={'bv'}>BETTER VALUE</option>
                    <option key="3" value={'premium'}>PREMIUM</option>
                    <option key="4" value={'rc'}>REVICON CLUB</option>
                </select>               
                    {/* <AddButton onClick={() => handleAddButton()}>
                        <i className="fas fa-plus-circle"></i>
                        <h1>Dodaj</h1>
                    </AddButton>
                    
                    <AddButton
                        backgroundColor={'tomato'}
                        onClick={() => {
                            shownRows.map((row, index) => {
                                editDocument('educations', row.id, 'date2', (row.date.slice(6) + row.date.slice(3, 5) + row.date.slice(0, 2)));
                            })
                        }}>
                        <h1 style={{ textAlign: 'center' }}>Poredaj po datumima</h1>
                    </AddButton> */}

                </div>

                <div style={{display: "flex"}}>
                    <div>
                        <h3 style={{color: "#f1f1f1"}}>Ukupan broj plaćenih pretplata: {totalPaid ? totalPaid : 0}</h3>
                        <h3>  {rows.length > 0 ? <CsvDownload style={{ marginLeft: 20 }} data={rows} filename={generateFileName()} >Preuzmi CSV</CsvDownload> : false }
                        </h3>
                    </div>
                </div>
                <div style={{display: "flex"}}>
                    <div style={{display: "flex", margin: "10px" }}>
                        <h3 style={{color: "#f1f1f1"}}>Revicon pretplata: {totalRevicon ? totalRevicon : 0}</h3>
                    </div>
                    <div style={{display: "block", margin: "10px"}}>
                        <h3 style={{color: "#f1f1f1"}}>BASIC pretplata: {totalBasic ? totalBasic : 0} </h3>
                        <p style={{color: "red"}}>plaćene({totalBasicPaid ? totalBasicPaid : 0 })</p>
                    </div>
                    <div style={{display: "block", margin: "10px"}}>
                        <h3 style={{color: "#f1f1f1"}}>BETTER VALUE pretplata: {totalBetterValue ? totalBetterValue : 0} </h3>
                        <p style={{color: "red"}}>plaćene({totalBetterValuePaid ? totalBetterValuePaid : 0 })</p>
                    </div>
                    <div style={{display: "block", margin: "10px"}}>
                        <h3 style={{color: "#f1f1f1"}}>PREMIUM pretplata: {totalPremium ? totalPremium : 0}</h3>
                        <p style={{color: "red"}}> plaćene({totalPremiumPaid ? totalPremiumPaid : 0 })</p>
                    </div>
                    <div style={{display: "block", margin: "10px"}}>
                        <h3 style={{color: "#f1f1f1"}}>REVICON CLUB pretplata: {totalRC ? totalRC : 0} </h3> 
                        <p style={{color: "red"}}>plaćene({totalRCPaid ? totalRCPaid : 0 })</p>
                    </div>
                </div>

                <Table>
                    <tbody>
                    <tr>
                        <th style={{ width: '10%' }}>Datum pretplate</th>
                        <th style={{ width: '35%' }}>Kompanija</th>
                        <th style={{ width: '15%' }}>Korisnik</th>
                        <th style={{ width: '10%' }}>Email</th>
                        <th style={{ width: '10%' }}>Grad</th>
                        <th style={{ width: '10%' }}>Broj telefona</th>
                        <th style={{ width: '35%' }}>Paket</th>
                        <th style={{ width: '15%' }}>Plaćeno</th>
                        <th style={{ width: '35%' }}>Predracun</th>
                        <th style={{ width: '15%' }}>Novi korisnik</th>
                        <th style={{ maxWidth: '10%' }}>Opis: </th>
                        <th style={{ width: '15%' }}></th>
                        <th style={{ width: '15%' }}>Uredi red</th>
                    </tr>
                    {/* <tr style={{ backgroundColor: '#222831', display: showAddRow ? 'revert' : 'none' }}>
                        <td>
                            <InputArea
                                shownRows={8}
                                onChange={(e) => setInputs({ ...inputs, date: e.target.value })}
                                value={inputs.date}
                            />
                        </td>
                        <td>
                            <InputArea
                                shownRows={8}
                                onChange={(e) => setInputs({ ...inputs, user: e.target.value })}
                                value={inputs.user}
                            />
                        </td>
                        <td>
                            <InputArea
                                shownRows={8}
                                onChange={(e) => setInputs({ ...inputs, paid: e.target.value })}
                                value={inputs.paid}
                            />
                        </td>
                        <td>
                            <InputArea
                                shownRows={8}
                                onChange={(e) => setInputs({ ...inputs, package_name: e.target.value })}
                                value={inputs.package_name}
                            />
                        </td>
                        <td>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                <AddButton onClick={() => handleSaveButton()}>
                                    <h1>Potvrdi</h1>
                                </AddButton>
                            </div>
                        </td>
                    </tr> */}
                    { shownRows.length > 0 ? shownRows.map((row, i) => 
                        <tr style={{ maxWidth: 300, background: (row.data.email.includes("@revicon.ba") || row.data.email.includes("@revicon.info")) ? "blue" : (row.data.paid && (!row.data.email.includes("@revicon.ba") || !row.data.email.includes("@revicon.info"))) ? "#E30710" : "#2d4059" }} key={'row_id_' + i}>
                            <td>{new Date(row.data.date).toLocaleDateString()}</td>
                            <td>{row.data.company}</td>
                            <td>{row.data.user}</td>
                            <td>{row.data.email}</td>
                            <td>{row.data.city}</td>
                            <td>{row.data.phone}</td>
                            <td>{row.data.package_name}</td>
                            <td>{editPaid && shownRows[updateRowId] === row ? EditPaid(row.data.paid, row.id, row.data) : row.data.paid ? 'Plaćeno':''}</td>
                            <td>{row.data.invoiceId}</td>
                            <th> 
                                <input type="checkbox" defaultChecked={row.data.isNewUser} value={row.data.isNewUser || ''} onClick={(e) => {editNewUser(row, e, row.id)}} />        
                            </th>
                            <td style={{maxwith: '10%'}}>{editPaid2 && shownRows[updateRowId2] === row ? handleDescription(row.id, row.data.description) : row.data.description}{editPaid2 && shownRows[updateRowId2] === row ?<div style={{display: 'flex', alignItems: 'center', justifyContent:'space-between'}}><div style={{ marginRight:'10px'}}><AddButton  onClick={() => handleAddButton2(row.id)}><i className="fas fa-plus-circle"></i><h1>Dodaj</h1></AddButton></div><CancleButton onClick={() => {setEditPaid2(false);setUpdateRowId2('');setInputs({ ...inputs, description: ''});}}><i className="fas fa-ban"></i><h1>Ponisti</h1></CancleButton></div>:<UpdateButton onClick={() => handleUpdate2(i)}><i className="fas fa-edit"></i></UpdateButton>}</td>
                            <td>
                                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                    <div style={{ marginRight:'10px'}}>
                                    <UpdateButton onClick={() => handleUpdate(i)}>
                                        <i className="fas fa-edit"></i>
                                    </UpdateButton>
                                    </div>
                                    <DeleteButton onClick={() => handleDelete(row.id)}>
                                        <i className="fas fa-trash-alt"></i>
                                    </DeleteButton>
                                </div>
                            </td>
                            <td>
                                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                    <div style={{ marginRight:'10px'}}>
                                    <UpdateButton onClick={() => handleUpdateRow(row.id)}>
                                        <i className="fas fa-edit"></i>
                                    </UpdateButton>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )
                        : false
                    }
                    </tbody>
                </Table>
            </QuestionsContainer>
        </Container>
    )
}

export default withRouter(PackageSubscriptions);